/* File Created: april 18, 2013 */
/* 	This document contains label specific styling for 
	Sunweb integration of the Group Portal */
/* GENERAL ELEMENTS */
/* line 6, ../scss/all.scss */
body {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  line-height: 1.5;
  background: url(../../images/background-totally.png) no-repeat center top #FFF;
  min-height: 100vh;
  display: block;
  margin: 0; }

/* line 16, ../scss/all.scss */
table, th, td {
  border: 0 none;
  padding: 0;
  text-align: left; }

/* line 17, ../scss/all.scss */
input {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px; }

/* line 18, ../scss/all.scss */
input[type="radio"], input[type="checkbox"] {
  margin: 0; }

/* line 19, ../scss/all.scss */
input[type="text"], textarea {
  border: solid 1px #CCC;
  padding: 4px; }

/* line 20, ../scss/all.scss */
select {
  padding: 4px;
  border: solid 1px #ccc; }

/* line 21, ../scss/all.scss */
fieldset {
  border: 0 none;
  margin: 0;
  padding: 0; }

/* line 24, ../scss/all.scss */
a {
  color: #0064D2;
  text-decoration: underline; }

/* line 25, ../scss/all.scss */
a:hover {
  color: #280854;
  text-decoration: none; }

/* line 26, ../scss/all.scss */
a img {
  border: 0 none; }

/* line 29, ../scss/all.scss */
h2 {
  font-size: 2.4em;
  letter-spacing: -0.05em;
  margin-bottom: 0; }

/* line 35, ../scss/all.scss */
h3 {
  color: #333;
  font-size: 1.5em;
  font-weight: normal;
  line-height: normal;
  padding: 0;
  text-align: left;
  margin: 0;
  margin-bottom: 0.5em; }

/* line 46, ../scss/all.scss */
.main, .header {
  width: 970px; }

/* line 50, ../scss/all.scss */
.footer, .main, .header {
  margin: 0 auto;
  padding: 5px; }

/* line 55, ../scss/all.scss */
.footer {
  font-size: 11px; }
  /* line 59, ../scss/all.scss */
  .footer a {
    text-decoration: none;
    color: #666; }
    /* line 63, ../scss/all.scss */
    .footer a:hover {
      text-decoration: underline;
      color: #000; }

/* line 70, ../scss/all.scss */
.section.footer {
  width: 965px;
  background: #FFF;
  clear: both;
  color: #666;
  font-size: 10px; }

/* line 78, ../scss/all.scss */
.main {
  background: #FFF; }
  /* line 83, ../scss/all.scss */
  .main:after {
    content: "";
    clear: both;
    display: block; }
  /* line 89, ../scss/all.scss */
  .main > hr {
    clear: both;
    background-repeat: repeat-x;
    background-position: center;
    height: 20px;
    border: none;
    margin: 0px;
    background-image: url(../images/trame-points.gif);
    margin-left: 5px;
    margin-right: 5px;
    border-style: none; }
  /* line 102, ../scss/all.scss */
  .main .sidebar {
    width: 245px;
    float: left;
    margin-right: 20px; }
  /* line 108, ../scss/all.scss */
  .main .content {
    background: #FFF;
    min-height: 400px;
    float: right;
    width: 705px; }
    /* line 114, ../scss/all.scss */
    .main .content h2 {
      margin-top: 10px; }

/* line 120, ../scss/all.scss */
.header {
  height: 145px; }
  /* line 125, ../scss/all.scss */
  .header:after {
    content: "";
    display: block;
    clear: both; }
  /* line 131, ../scss/all.scss */
  .header a {
    display: block;
    width: 190px;
    height: auto;
    margin-top: 40px;
    margin-right: 30px;
    float: left;
    background-size: 416px; }
    /* line 140, ../scss/all.scss */
    .header a img {
      max-width: 230px;
      max-height: 100%; }
  /* line 146, ../scss/all.scss */
  .header .header-contact {
    width: 310px;
    height: 125px;
    float: left; }

/* line 153, ../scss/all.scss */
.grouptravel {
  min-height: 400px; }

/* GENERAL CLASSES */
/* icons */
/* line 157, ../scss/all.scss */
.icon {
  display: inline-block;
  width: 15px;
  height: 15px;
  background-image: url("../images/grouptravel/icons-sprite.png");
  background-repeat: no-repeat;
  background-color: transparent; }

/* line 166, ../scss/all.scss */
.icon.down-arrow-90 {
  background-position: 0 -81px; }

/* line 170, ../scss/all.scss */
.icon.add-sign {
  background-position: -2px -96px; }

/* line 174, ../scss/all.scss */
.icon.email {
  background-position: 0 -131px; }

/* line 178, ../scss/all.scss */
.icon.download {
  background-position: 1px -146px; }

/* line 182, ../scss/all.scss */
.icon.small-delete {
  background-position: 0 -161px; }

/* line 186, ../scss/all.scss */
.icon.conversation {
  background-position: 0 -176px; }

/* line 190, ../scss/all.scss */
.action-text {
  font-weight: bold;
  color: #1E9DD4; }

/* line 195, ../scss/all.scss */
table tr.highlighted td {
  background-color: #333 !important;
  color: #FFF !important; }

/* line 200, ../scss/all.scss */
table tr.highlighted td a:hover {
  color: #FFF; }

/* Buttons styling */
/* line 204, ../scss/all.scss */
.buttons {
  text-align: right; }

/* line 208, ../scss/all.scss */
.button {
  background-color: #00A8EC;
  color: #FFF;
  cursor: pointer;
  font-size: 0.916em;
  line-height: 26px;
  padding: 0 20px;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: normal; }

/* line 222, ../scss/all.scss */
.button:hover {
  color: #FFF; }

/* line 227, ../scss/all.scss */
button.button,
input.button {
  min-height: 26px;
  border: 0 none; }

/* line 233, ../scss/all.scss */
.grouptravel .button.blue,
.button.blue,
.button.colored {
  background-color: #280854;
  color: #FFF; }

/* line 240, ../scss/all.scss */
.pull-right {
  float: right !important; }

/* Main layout styling */
/* Shared style for the modal overlay screens */
/* line 245, ../scss/all.scss */
.modal-content,
.overlay-content {
  display: none;
  min-width: 400px;
  max-width: 800px; }

/* line 252, ../scss/all.scss */
.simplemodal-overlay {
  background-color: #000; }

/* line 256, ../scss/all.scss */
.simplemodal-container {
  background-color: #FFF;
  padding: 0;
  position: relative;
  border-radius: 4px; }

/* line 263, ../scss/all.scss */
.simplemodal-data {
  width: 800px;
  min-height: 200px; }

/* line 268, ../scss/all.scss */
.simplemodal-container a.modalCloseImg {
  background: url("../../images/grouptravel/icons-sprite.png") no-repeat scroll 0 -60px transparent;
  cursor: pointer;
  display: block;
  height: 21px;
  position: absolute;
  right: 20px;
  top: 20px;
  width: 20px; }

/* line 279, ../scss/all.scss */
.simplemodal-container .simplemodal-wrap h4 {
  font-size: 16px;
  text-transform: uppercase;
  line-height: 20px;
  margin-bottom: 20px;
  margin-top: 0;
  padding: 20px;
  background-color: #0064D2;
  color: #FFF; }

/* line 290, ../scss/all.scss */
.simplemodal-container .simplemodal-data > * {
  padding: 0 20px; }

/* line 294, ../scss/all.scss */
.simplemodal-container .simplemodal-data .buttons {
  margin-top: 2em; }

/* tab content areas */
/* line 301, ../scss/all.scss */
.multiple-content-tabs .tabs {
  list-style-type: none;
  margin: 0;
  padding: 0; }

/* line 307, ../scss/all.scss */
.multiple-content-tabs .tabs li {
  margin: 0;
  padding: 0; }

/* line 312, ../scss/all.scss */
.multiple-content-tabs.accommodations .tabs {
  margin-bottom: 30px; }

/* line 316, ../scss/all.scss */
.multiple-content-tabs.accommodations .tabs li {
  margin-bottom: 5px;
  border: solid 1px #eee;
  padding: 10px;
  overflow: hidden; }

/* line 323, ../scss/all.scss */
.multiple-content-tabs.accommodations .tabs li a {
  display: block;
  width: auto;
  overflow: hidden;
  position: relative;
  padding-right: 0;
  height: 80px; }

/* line 332, ../scss/all.scss */
.multiple-content-tabs.accommodations .tabs li:hover,
.multiple-content-tabs.accommodations .tabs .ui-tabs-active {
  background-color: #e0e0e0; }

/* line 337, ../scss/all.scss */
.multiple-content-tabs.accommodations .tabs img {
  width: 120px;
  height: auto;
  max-height: 120px;
  margin: 0 auto;
  margin-right: 20px;
  float: left; }

/* line 346, ../scss/all.scss */
.multiple-content-tabs.accommodations .tabs .tab-acco-name {
  font-size: 14px;
  font-weight: bold;
  float: left;
  max-width: 85%;
  position: relative;
  top: 35%; }

/* line 355, ../scss/all.scss */
.multiple-content-tabs.accommodations .tabs .active-tab-marker {
  margin: 0 auto;
  position: absolute;
  right: 30px;
  top: 40%;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #000; }

/* line 367, ../scss/all.scss */
.multiple-content-tabs.accommodations .tabs .ui-tabs-active .active-tab-marker {
  border-top: 10px solid #000;
  border-bottom: 0; }

/* line 373, ../scss/all.scss */
.offer-listing-container input[type="text"],
.offer-listing-container select {
  min-width: 211px;
  max-width: 315px; }

/* line 379, ../scss/all.scss */
.offer-listing-container select {
  min-width: 221px; }

/* line 383, ../scss/all.scss */
.breadcrumbs a {
  text-decoration: none; }

/* line 387, ../scss/all.scss */
.breadcrumbs a:hover {
  color: #FFF;
  text-decoration: underline; }

@media terminated {
  /* Sidebar section content */
  /* line 394, ../scss/all.scss */
  .sidebar-title {
    width: auto;
    white-space: nowrap;
    overflow: visible;
    font-size: 1.8em;
    margin-top: 43px;
    margin-bottom: 7px; }

  /* line 403, ../scss/all.scss */
  .sidebar ul {
    padding: 0;
    margin: 0;
    margin-bottom: 20px;
    list-style: none;
    overflow: hidden;
    font-size: 1em;
    line-height: 1.8em;
    word-spacing: -1px; }

  /* line 414, ../scss/all.scss */
  .sidebar li {
    padding: 0;
    overflow: hidden;
    background-color: #00A8EC;
    margin-bottom: 1px;
    text-transform: uppercase; }

  /* line 422, ../scss/all.scss */
  .sidebar li.active,
  .sidebar li:hover {
    background-color: #280854; }

  /* line 427, ../scss/all.scss */
  .sidebar a {
    text-decoration: none;
    font-weight: normal;
    color: #FFF;
    font-size: 11px;
    font-weight: bold;
    display: block;
    padding: 5px 10px; }

  /* line 437, ../scss/all.scss */
  .sidebar a:hover {
    text-decoration: none;
    color: #FFF; }

  /* line 442, ../scss/all.scss */
  .sidebar ul li.separate {
    margin-top: 2em; } }
/* Main section content */
/* line 447, ../scss/all.scss */
.main-c {
  min-height: 540px;
  /* avoid footer space */ }

/* line 451, ../scss/all.scss */
.title-static h2 {
  line-height: 1.111em;
  color: #000; }

/* line 456, ../scss/all.scss */
.expirydate {
  margin-bottom: 2em;
  font-size: 0.91em; }

/* Page actions area */
/* line 461, ../scss/all.scss */
.page-actions {
  height: 25px;
  list-style-type: none;
  overflow: hidden;
  padding: 0;
  margin: 0;
  text-align: right; }

/* line 470, ../scss/all.scss */
.page-actions li {
  display: inline-block; }

/* line 474, ../scss/all.scss */
.page-actions li a {
  display: block;
  padding: 0 10px 0 30px;
  text-transform: uppercase;
  line-height: 20px;
  background: url(../../images/grouptravel/icons-sprite.png) no-repeat left center transparent;
  text-decoration: none;
  font-size: 11px; }

/* line 484, ../scss/all.scss */
.page-actions li a:hover {
  text-decoration: underline; }

/* line 488, ../scss/all.scss */
.page-actions .check-icon {
  background-position: 0 0; }

/* line 492, ../scss/all.scss */
.page-actions .print-icon {
  background-position: 0 -20px; }

/* line 496, ../scss/all.scss */
.page-actions .help-icon {
  background-position: 0 -40px; }

/* line 500, ../scss/all.scss */
.page-actions .logout-icon {
  background-position: 0 -111px; }

/* line 504, ../scss/all.scss */
.page-actions .email-icon {
  background-position: 0 -191px; }

@media terminated {
  /* styling of the tables containing the offer data */
  /* line 511, ../scss/all.scss */
  .offer-listing-container table,
  table.offer-listing-container {
    margin-bottom: 2em;
    width: 100%; }

  /* line 518, ../scss/all.scss */
  .offer-listing-container .h2 {
    background-color: #FFF !important;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.4em;
    font-weight: bold;
    line-height: normal;
    margin-top: 20px;
    padding: 0px;
    text-transform: uppercase; }

  /* line 529, ../scss/all.scss */
  .offer-listing-container .h3 {
    background-color: #FFF !important;
    font-family: Arial, Helvetica, sans-serif;
    line-height: normal;
    margin-top: 20px;
    padding: 5px;
    /* text-transform:uppercase; */ }

  /* line 538, ../scss/all.scss */
  .offer-listing-container .h2 > span,
  .offer-listing-container .h3 > span {
    display: block;
    border-bottom: solid 1px #ccc;
    padding-bottom: 0.5em; }

  /* line 545, ../scss/all.scss */
  .offer-listing-container .h3 > span {
    padding-top: 1em;
    padding-bottom: 2px;
    font-size: 1.2em;
    font-weight: bold;
    text-transform: uppercase; }

  /* line 553, ../scss/all.scss */
  .offer-listing-container .h2 p,
  .offer-listing-container .h2 li,
  .offer-listing-container .h2 div,
  .offer-listing-container .h3 p,
  .offer-listing-container .h3 li,
  .offer-listing-container .h3 div {
    font-weight: normal;
    font-family: Verdana, Geneva, sans-serif;
    font-size: 12px; }

  /* line 564, ../scss/all.scss */
  .offer-listing-container .ui-accordion-header {
    font-family: sansa_prosemibold, Verdana, Geneva, sans-serif;
    font-size: 1.25em;
    line-height: normal;
    color: #333;
    padding-bottom: 0.3em;
    border-bottom: solid 1px #ccc;
    background-color: transparent;
    padding-left: 0;
    text-align: left;
    font-weight: normal; }

  /* line 577, ../scss/all.scss */
  .offer-listing-container td {
    padding: 3px 5px;
    height: 20px;
    line-height: 1.5;
    vertical-align: top; }

  /* line 584, ../scss/all.scss */
  .offer-listing-container tr:nth-child(2n) td {
    background-color: #EFEFEF; }

  /* line 588, ../scss/all.scss */
  .offer-listing-container.acco tr:nth-child(2n) td {
    background-color: transparent; }

  /* line 592, ../scss/all.scss */
  .offer-listing-container tr td.buttons {
    background-color: transparent; }

  /* line 596, ../scss/all.scss */
  .offer-listing-container tr .h3 > span.intro {
    margin: 1em 0;
    font-weight: normal;
    font-family: Verdana, Geneva, sans-serif;
    font-size: 12px;
    line-height: normal;
    border: none;
    padding: 0; }

  /* two column table */
  /* line 606, ../scss/all.scss */
  .offer-listing-container table.two-col td:first-child {
    width: 33%;
    font-weight: bold; }

  /* line 611, ../scss/all.scss */
  .offer-listing-container td:first-child + td {
    width: 66%; }

  /* line 615, ../scss/all.scss */
  .offer-listing-container table.two-col.equal-columns td {
    width: 50%; }

  /* line 619, ../scss/all.scss */
  .offer-listing-container table.two-col.first-col-large td:first-child {
    width: 78%; }

  /* line 623, ../scss/all.scss */
  .offer-listing-container table.two-col.first-col-large td:first-child + td {
    width: 12%; }

  /* three column table */
  /* line 627, ../scss/all.scss */
  .offer-listing-container table.three-col td:first-child {
    width: 1%; }

  /* line 631, ../scss/all.scss */
  .offer-listing-container tr td + td + td {
    width: 12%; }

  /* line 635, ../scss/all.scss */
  .offer-listing-container table.three-col.equal-columns td {
    width: 33%; } }
/* photo gallery */
/* line 640, ../scss/all.scss */
.offer-listing-container .photo-gallery {
  padding-left: 0;
  padding-right: 0; }

/* line 645, ../scss/all.scss */
.detail-gallery li.gallery-link {
  background-color: #930121;
  width: 38.5%; }

/* line 650, ../scss/all.scss */
.detail-gallery li.gallery-link span {
  color: #FFFFFF;
  cursor: pointer;
  display: block;
  text-align: center; }

/* accordion feature styling */
/* line 657, ../scss/all.scss */
.small-accordion .ui-accordion-header {
  display: block;
  background-color: #DDDDDD;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 20px;
  padding: 5px;
  font-size: 1.2em;
  border: none;
  font-family: Arial, Helvetica, sans-serif; }

/* line 669, ../scss/all.scss */
.small-accordion .ui-accordion-header .ui-accordion-header-icon {
  position: absolute;
  right: 8px;
  top: 35%;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  margin-top: 0;
  padding: 0; }

/* line 681, ../scss/all.scss */
.small-accordion .ui-accordion-header .ui-icon-triangle-1-e {
  border-top: 6px solid #000; }

/* line 685, ../scss/all.scss */
.small-accordion .ui-accordion-header .ui-icon-triangle-1-s {
  border-bottom: 6px solid #000; }

/* line 689, ../scss/all.scss */
.small-accordion .ui-accordion-content {
  margin-top: 10px; }

/* line 693, ../scss/all.scss */
.small-accordion .ui-accordion-content table td.h3 span {
  background-color: transparent; }

/* line 697, ../scss/all.scss */
.small-accordion .ui-accordion-content p:first-child {
  margin-top: 0; }

        /* .small-accordion .ui-accordion-header:hover .ui-icon-triangle-1-e { border-top: 6px solid #DF1D42; }
.small-accordion .ui-accordion-header:hover .ui-icon-triangle-1-s { border-bottom: 6px solid #DF1D42; }
 */
/* line 703, ../scss/all.scss */
.small-accordion .ui-accordion-header:hover {
  color: #00A8EC; }

/* sidebar accordion nav */
/* line 707, ../scss/all.scss */
#sidebar .ui-accordion-header .ui-accordion-header-icon {
  top: 10px;
  right: 8px;
  border-left-width: 6px;
  border-right-width: 6px; }

/* line 714, ../scss/all.scss */
#sidebar .ui-accordion-header .ui-icon-triangle-1-e {
  border-top: 6px solid #FFF; }

/* line 718, ../scss/all.scss */
#sidebar .ui-accordion-header .ui-icon-triangle-1-s {
  border-bottom: 6px solid #FFF; }

/* line 722, ../scss/all.scss */
#sidebar .ui-accordion-header li {
  cursor: pointer;
  background-color: #000;
  color: #FFF;
  font-size: 1em;
  text-transform: uppercase;
  padding-left: 5px;
  line-height: 26px; }

/* top registration progress bar */
/* line 732, ../scss/all.scss */
.registration-steps {
  position: relative;
  top: 0;
  left: 0;
  height: 60px;
  width: 520px;
  overflow: hidden; }

/* line 741, ../scss/all.scss */
.registration-steps ul {
  padding: 0;
  margin: 0; }

/* line 746, ../scss/all.scss */
.registration-steps li {
  list-style-type: none;
  display: inline-block;
  color: #ccc;
  cursor: default;
  height: 35px;
  padding: 0;
  padding-top: 10px;
  margin: 0;
  margin-left: 30px;
  font-family: sansa_prosemibold, Verdana, Geneva, sans-serif;
  font-size: 36px;
  text-transform: uppercase;
  text-align: center;
  position: relative; }

/* line 763, ../scss/all.scss */
.registration-steps li.completed {
  color: #1e9dd4; }

/* line 767, ../scss/all.scss */
.registration-steps li:before {
  content: "\203A";
  /* > */
  position: absolute;
  left: -22px;
  top: 4px;
  color: #CCC; }

/* line 775, ../scss/all.scss */
.registration-steps li:first-child {
  margin-left: 0; }

/* line 779, ../scss/all.scss */
.registration-steps li:first-child:before {
  content: ""; }

/* line 783, ../scss/all.scss */
.registration-steps li span {
  font-size: 11px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: normal;
  display: block;
  padding-top: 12px;
  letter-spacing: 0.08em; }

/* line 792, ../scss/all.scss */
.multiple-textinputs input[type="text"] {
  min-width: 0;
  width: 57px; }

/* Login screen */
/* line 800, ../scss/all.scss */
.login .login-header {
  width: 530px;
  margin-top: 2em; }

/* line 805, ../scss/all.scss */
.login .login-header:after {
  clear: both;
  content: "";
  display: block; }

/* line 811, ../scss/all.scss */
.login .login-header > h3 {
  color: #333;
  font-size: 1.5em;
  font-weight: normal;
  line-height: normal;
  padding: 0;
  text-align: left;
  margin: 0;
  margin-bottom: 0.5em;
  float: left; }

/* line 823, ../scss/all.scss */
.login .login-header > h3 + span {
  float: right;
  font-style: italic;
  line-height: 3em; }

/* line 829, ../scss/all.scss */
.login .login-container {
  width: 530px; }

/* line 833, ../scss/all.scss */
.login .login-container fieldset {
  padding: 1em 0; }

/* line 837, ../scss/all.scss */
.login .login-container .error-command:after {
  display: block;
  content: "";
  clear: both;
  margin-bottom: 1em; }

/* line 844, ../scss/all.scss */
.login .login-container > .failureNotification {
  margin-bottom: 1em; }

/* line 848, ../scss/all.scss */
.login .login-container > .failureNotification ul {
  margin: 0;
  padding: 0;
  list-style: none; }

/* line 854, ../scss/all.scss */
.login .login-container > .failureNotification ul li {
  margin: 0;
  padding: 0; }

/* line 859, ../scss/all.scss */
.login .forgot-password {
  display: block;
  line-height: 2em;
  clear: both;
  font-size: 0.918em; }

/* line 866, ../scss/all.scss */
.login-form {
  border: dotted #999;
  border-width: 1px 0 1px 0; }

/* line 871, ../scss/all.scss */
.login-form legend {
  height: 0;
  left: -999em;
  position: absolute; }

/* line 877, ../scss/all.scss */
.login-form ul {
  list-style: none;
  margin: 0;
  padding: 0; }

/* line 883, ../scss/all.scss */
.login-form li {
  margin-bottom: 3px; }

/* line 887, ../scss/all.scss */
.login-form li:after {
  clear: both;
  content: "";
  display: block; }

/* line 893, ../scss/all.scss */
.login-form li label {
  display: inline-block;
  width: 200px;
  line-height: 2.18em;
  font-weight: bold; }

/* line 900, ../scss/all.scss */
.login-form li input[type="text"],
.login-form li input[type="password"] {
  padding: 2px;
  height: 20px;
  width: 300px;
  display: inline-block;
  float: right; }

/* line 909, ../scss/all.scss */
.login-form li input[type="submit"] {
  float: right;
  cursor: pointer;
  margin-top: 1em; }

/* line 918, ../scss/all.scss */
.login-form li .guid {
  margin-top: 2em; }

/* line 922, ../scss/all.scss */
.login-form li .forgot-pass-link {
  display: inline-block;
  line-height: 26px;
  width: 200px; }

/* line 928, ../scss/all.scss */
.aside-login-form {
  margin-top: 40px;
  width: 165px;
  overflow: hidden; }

/* line 934, ../scss/all.scss */
.aside-login-form .login-form {
  padding: 0;
  padding-top: 10px;
  width: 100%; }

/* line 940, ../scss/all.scss */
.aside-login-form .login-form ul {
  width: 165px;
  margin-bottom: 20px; }

/* line 945, ../scss/all.scss */
.aside-login-form .login-form ul li {
  display: block;
  margin: 0;
  margin-bottom: 5px; }

/* line 951, ../scss/all.scss */
.aside-login-form .login-form ul li label {
  font-weight: bold;
  width: auto;
  margin: 0;
  display: block;
  line-height: 16px; }

/* line 959, ../scss/all.scss */
.aside-login-form .login-form ul li input[type="text"] {
  width: 159px; }

/* line 963, ../scss/all.scss */
.aside-login-form .login-form ul li input[type="submit"] {
  margin-top: 5px; }

/* line 967, ../scss/all.scss */
.aside-login-form .login-form ul li .forgot-pass-link {
  width: auto;
  margin: 0;
  display: block;
  font-size: 11px;
  line-height: 14px;
  padding-top: 5px; }

/* overview screen */
/* line 976, ../scss/all.scss */
.offer-listing-container.overview table {
  margin-bottom: 1em; }

/* line 980, ../scss/all.scss */
.offer-listing-container.overview .buttons {
  text-align: right;
  padding: 2px 0;
  border-bottom: solid 0px #d7d7d7; }

/* line 986, ../scss/all.scss */
.offer-listing-container tr:first-child .h3 span {
  margin-top: 0; }

/* line 990, ../scss/all.scss */
.offer-listing-container tr .h3 span a {
  float: right; }

/* line 994, ../scss/all.scss */
.features-list {
  margin: 0;
  padding-left: 15px; }

/* line 999, ../scss/all.scss */
.features-list li {
  width: 44%;
  float: left;
  margin-right: 20px;
  margin-bottom: 5px; }

/* static text pages */
/* line 1006, ../scss/all.scss */
.offer-listing-container.static tr:nth-child(2n) td {
  background-color: #FFF; }

/* user-registration-screens */
/* line 1010, ../scss/all.scss */
#sidebar .pricebox {
  border: solid 1px #1e9dd4;
  color: #FFF;
  width: 163px;
  margin-top: 2em; }

/* line 1017, ../scss/all.scss */
#sidebar .pricebox h4 {
  background-color: #1e9dd4;
  font-size: 1em;
  margin: 0;
  padding: 5px; }

/* line 1024, ../scss/all.scss */
#sidebar .pricebox .info {
  color: #000;
  font-size: 11px;
  line-height: 1.2;
  padding: 5px;
  margin-bottom: 2em; }

/* line 1032, ../scss/all.scss */
#sidebar .pricebox .totalprice {
  text-align: center; }

/* line 1036, ../scss/all.scss */
#sidebar .pricebox .totalprice span:first-child {
  text-transform: uppercase;
  font-size: 11px;
  line-height: 26px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  background-color: #1e9dd4; }

/* line 1046, ../scss/all.scss */
#sidebar .pricebox .totalprice span + span {
  display: block;
  font-size: 1.5em;
  font-weight: bold;
  line-height: 26px;
  background-color: #000;
  padding: 1px; }

/* line 1055, ../scss/all.scss */
#sidebar .pricebox .price-details {
  list-style-type: none;
  margin: 0;
  margin-bottom: 2em;
  padding: 0 5px;
  color: #000; }

/* line 1063, ../scss/all.scss */
#sidebar .pricebox .price-details li {
  padding: 5px 0 2px 0;
  margin: 0;
  border-bottom: solid 1px #ccc; }

/* line 1069, ../scss/all.scss */
#sidebar .pricebox .price-details li span {
  font-weight: bold;
  display: inline-block;
  line-height: 1.2;
  font-size: 12px; }

/* line 1076, ../scss/all.scss */
#sidebar .pricebox .price-details li span:first-child {
  width: 60%;
  text-overflow: ellipsis;
  overflow: hidden; }

/* line 1082, ../scss/all.scss */
#sidebar .pricebox .price-details li span + span {
  width: 37%;
  text-align: right;
  float: right; }

/* line 1088, ../scss/all.scss */
.terms-agreement {
  background-color: #EEE;
  padding: 10px 20px;
  margin-bottom: -20px;
  border-top: 1px solid #1E9DD4; }

/* line 1095, ../scss/all.scss */
.terms-agreement:after {
  clear: both;
  display: block;
  content: ""; }

/* line 1101, ../scss/all.scss */
.terms-agreement p {
  margin-top: 0; }

/* line 1105, ../scss/all.scss */
.terms-agreement .terms-checkbox {
  width: 50%;
  display: inline-block; }

/* line 1110, ../scss/all.scss */
.terms-agreement .terms-checkbox input {
  margin: 0;
  margin-right: 10px;
  margin-bottom: 5px; }

/* line 1116, ../scss/all.scss */
.terms-agreement .terms-checkbox label {
  font-weight: bold; }

/* line 1120, ../scss/all.scss */
.terms-agreement > a {
  float: right;
  display: inline-block;
  padding-top: 5px; }

/* line 1126, ../scss/all.scss */
.final-button {
  background-color: #1C1E20;
  color: #FFF;
  border: 0 none;
  cursor: pointer;
  font-family: Verdana, Geneva, sans-serif;
  font-size: 1.89em;
  height: auto;
  text-transform: uppercase;
  width: auto;
  margin-top: 1em; }

/* line 1139, ../scss/all.scss */
.payment-due-terms {
  vertical-align: middle; }

/* line 1143, ../scss/all.scss */
.payment-due-terms span {
  display: inline-block;
  float: right;
  width: 30%;
  text-align: right;
  font-weight: bold;
  font-style: italic; }

/* line 1152, ../scss/all.scss */
.payment-due-terms a {
  background-color: #1E9DD4;
  color: #FFF;
  display: inline-block;
  font-size: 11px;
  line-height: 26px;
  text-transform: uppercase;
  text-decoration: none;
  float: right;
  padding: 0 5px; }

/* registration navigation bar - bottom */
/* line 1164, ../scss/all.scss */
.registration-navigation {
  border-top: solid 2px #1e9dd4;
  padding-top: 15px;
  margin: 20px 0;
  text-align: right;
  position: relative; }

/* line 1172, ../scss/all.scss */
.registration-navigation:before {
  content: "";
  border-top: solid 1px #1e9dd4;
  position: absolute;
  top: 2px;
  width: 100%;
  display: block; }

/* line 1181, ../scss/all.scss */
.registration-navigation a {
  display: inline-block;
  color: #FFF;
  text-transform: uppercase;
  font-size: 0.912em;
  text-align: center;
  padding: 7px 10px 5px;
  position: relative;
  text-decoration: none;
  min-width: 70px; }

/* line 1196, ../scss/all.scss */
.registration-navigation a:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  border-top: 13px solid transparent;
  border-bottom: 13px solid transparent;
  z-index: 1; }

/* line 1208, ../scss/all.scss */
.registration-navigation .cancel {
  background-color: #666;
  float: left;
  min-width: 85px; }

/* line 1214, ../scss/all.scss */
.registration-navigation .cancel:before {
  display: none; }

/* line 1218, ../scss/all.scss */
.registration-navigation .previous {
  background-color: #1e9dd4; }

/* line 1222, ../scss/all.scss */
.registration-navigation .previous:before {
  border-right: solid 13px #1e9dd4;
  left: -13px; }

/* line 1227, ../scss/all.scss */
.registration-navigation .next {
  background-color: #1e9dd4;
  margin-left: 5px;
  margin-right: 13px; }

/* line 1233, ../scss/all.scss */
.registration-navigation .next:before {
  border-left: solid 13px #1e9dd4;
  right: -13px;
  left: auto; }

/* registration choice content overlay */
/* line 1239, ../scss/all.scss */
.registration-choice-content ul.registration-choice {
  list-style-type: none;
  border-bottom: 1px solid #CCC;
  margin-bottom: 30px;
  padding-bottom: 30px; }

/* line 1246, ../scss/all.scss */
.registration-choice-content ul.registration-choice li {
  margin-bottom: 10px; }

/* line 1250, ../scss/all.scss */
.registration-choice-content ul.registration-choice li input {
  margin-right: 10px; }

/* line 1254, ../scss/all.scss */
.registration-choice-content ul.registration-choice li label {
  font-weight: bold; }

/* line 1258, ../scss/all.scss */
.registration-choice-content ul.registration-choice li .registration-choice-submit {
  display: block; }

/* line 1265, ../scss/all.scss */
.registration-choice-content .personalize-room .create-room-switch {
  margin-bottom: 20px;
  text-align: left; }

/* line 1270, ../scss/all.scss */
.registration-choice-content .personalize-room .create-room-switch input[type="checkbox"] {
  display: inline-block;
  margin-right: 2px;
  margin-bottom: 2px; }

/* line 1276, ../scss/all.scss */
.registration-choice-content .personalize-room .create-room-switch label {
  display: inline-block; }

/* line 1280, ../scss/all.scss */
.registration-choice-content .personalize-room .create-new-room {
  padding-bottom: 20px;
  margin-bottom: 20px;
  height: auto; }

/* line 1286, ../scss/all.scss */
.registration-choice-content .personalize-room .create-new-room.disabled {
  height: 0; }

/* line 1290, ../scss/all.scss */
.registration-choice-content .personalize-room .create-new-room.disabled * {
  opacity: 0.7; }

/* line 1297, ../scss/all.scss */
.registration-choice-content .roomtype-listing input[type="radio"] {
  margin-bottom: 2px;
  margin-right: 2px; }

/* line 1302, ../scss/all.scss */
.registration-choice-content .roomtype-listing td.h3:first-child span {
  width: 390px; }

/* line 1306, ../scss/all.scss */
.registration-choice-content .roomtype-listing td.h3:first-child + td span {
  width: 140px; }

/* line 1310, ../scss/all.scss */
.registration-choice-content .roomtype-listing td.h3:first-child + td + td span {
  width: 140px; }

/* line 1314, ../scss/all.scss */
.registration-choice-content .registration-navigation {
  margin-bottom: 0; }

/* principal booker screens */
/* line 1318, ../scss/all.scss */
.principal-booker-name:after {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url(../images/grouptravel/icons-sprite.png) no-repeat 0 -210px;
  position: relative;
  top: 3px;
  left: 3px; }

/* line 1329, ../scss/all.scss */
.help-content-overlay {
  display: none; }

/* line 1334, ../scss/all.scss */
.participant-list-search > * {
  display: inline-block;
  text-align: left; }

/* line 1339, ../scss/all.scss */
.participant-list-search label {
  font-weight: bold;
  padding: 0 5px;
  line-height: 24px; }

/* line 1345, ../scss/all.scss */
.participant-list-search input[type="radio"] {
  margin: 6px 0 9px 0; }

/* line 1349, ../scss/all.scss */
.participant-list-search input[type="text"] {
  background-color: #FFF;
  padding: 4px;
  border: solid 1px #CCC;
  height: 14px; }

/* line 1356, ../scss/all.scss */
.participant-list-search input[type="submit"] {
  border: 0;
  width: 24px;
  height: 24px;
  background: url(../images/grouptravel/blue-arrow-big.png) no-repeat transparent;
  text-indent: -999em;
  padding: 0;
  cursor: pointer; }

/* line 1366, ../scss/all.scss */
.participant-list-container {
  width: 700px;
  max-height: 470px;
  overflow: auto; }

/* line 1372, ../scss/all.scss */
.participant-list-container table {
  min-width: 0;
  margin-top: -2px;
  margin-bottom: 0; }

/* line 1378, ../scss/all.scss */
.participant-list-container table td {
  padding: 3px; }

/* line 1382, ../scss/all.scss */
.participant-list-container table tr:first-child td,
.participant-list-container table tr.highlighted:first-child td {
  font-size: 1em;
  background-color: #DDDDDD;
  font-weight: bold;
  margin-top: 20px;
  padding: 5px;
  border-left: 2px solid #FFFFFF;
  vertical-align: middle; }

/* used when the participants are registering for themselves (principal booker does not register participants) */
/* line 1393, ../scss/all.scss */
.participant-list-container table tr td:first-child {
  border-left: 0;
  width: 20px; }

/* checkbox */
/* line 1398, ../scss/all.scss */
.participant-list-container table tr td:first-child + td {
  width: 142px; }

/* name */
/* line 1402, ../scss/all.scss */
.participant-list-container table tr td:first-child + td + td {
  width: 75px; }

/* deelnemer # */
/* line 1406, ../scss/all.scss */
.participant-list-container table tr td:first-child + td + td + td {
  width: 123px; }

/* kamertyp */
/* line 1410, ../scss/all.scss */
.participant-list-container table tr td:first-child + td + td + td span {
  width: 123px;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; }

/* kamertyp */
/* line 1418, ../scss/all.scss */
.participant-list-container table tr td:first-child + td + td + td + td {
  width: 28px; }

/* kamer nr */
/* line 1422, ../scss/all.scss */
.participant-list-container table tr td:first-child + td + td + td + td + td {
  width: 37px; }

/* status */
/* line 1426, ../scss/all.scss */
.participant-list-container table tr td:first-child + td + td + td + td + td + td {
  width: 75px; }

/* price */
/* line 1430, ../scss/all.scss */
.participant-list-container table tr td:first-child + td + td + td + td + td + td + td {
  width: 75px; }

/* paid price */
/* when principal booker is the main registerer, there is no 'status' and 'paid' columns */
/* 37 + 23 = 60 */
/* line 1435, ../scss/all.scss */
.participant-list-container.principal-registerer table tr td:first-child {
  border-left: 0;
  width: 20px; }

/* checkbox */
/* line 1440, ../scss/all.scss */
.participant-list-container.principal-registerer table tr td:first-child + td {
  width: 202px; }

/* name */
/* line 1444, ../scss/all.scss */
.participant-list-container.principal-registerer table tr td:first-child + td + td {
  width: 75px; }

/* deelnemer # */
/* line 1448, ../scss/all.scss */
.participant-list-container.principal-registerer table tr td:first-child + td + td + td {
  width: 75px; }

/* total price */
/* line 1452, ../scss/all.scss */
.participant-list-container.principal-registerer table tr td:f1irst-child + td + td + td span {
  width: 75px;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; }

/* total price */
/* line 1460, ../scss/all.scss */
.participant-list-container.principal-registerer table tr td:first-child + td + td + td + td {
  width: 75px; }

/* paid */
/* line 1464, ../scss/all.scss */
.participant-list-container.principal-registerer table tr td:first-child + td + td + td + td + td {
  width: 75px; }

/* remaining to pay */
/* line 1468, ../scss/all.scss */
.participant-list-container.principal-registerer table tr td:first-child + td + td + td + td + td + td {
  width: 52px; }

/* action icons column */
/* used when multi registration */
/* line 1473, ../scss/all.scss */
.participant-list-container.multi-registration table tr td:first-child {
  border-left: 0;
  width: 205px; }

/* name */
/* line 1478, ../scss/all.scss */
.participant-list-container.multi-registration table tr td:first-child + td {
  width: 75px; }

/* birth date */
/* line 1482, ../scss/all.scss */
.participant-list-container.multi-registration table tr td:first-child + td + td {
  width: 214px; }

/* email */
/* line 1486, ../scss/all.scss */
.participant-list-container.multi-registration table tr td:first-child + td + td span {
  width: 224px;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; }

/* email */
/* line 1494, ../scss/all.scss */
.participant-list-container.multi-registration table tr td:first-child + td + td + td {
  width: 75px; }

/* price */
/* line 1498, ../scss/all.scss */
.participant-list-container.multi-registration table tr td:first-child + td + td + td + td {
  width: 54px; }

/* actions (+32px)*/
/* line 1502, ../scss/all.scss */
.participant-list-container.multi-registration td.h3 {
  padding: 0; }

/* line 1506, ../scss/all.scss */
.participant-list-container.multi-registration td.h3 span strong {
  font-weight: 700;
  float: right; }

/* line 1511, ../scss/all.scss */
.participant-list-container.multi-registration tr.available-place td {
  background-color: #FFF;
  padding: 0; }

/* line 1516, ../scss/all.scss */
.participant-list-container.multi-registration tr.available-place td span {
  border: dotted 1px #999;
  display: block;
  margin-bottom: 2px;
  padding: 2px; }

/* line 1523, ../scss/all.scss */
.participant-list-container.multi-registration tr.available-place td span a {
  display: block;
  text-align: right; }

/* line 1528, ../scss/all.scss */
.participant-list-container.multi-registration td .icon.conversation {
  vertical-align: middle;
  margin-left: 5px; }

/* line 1533, ../scss/all.scss */
.room-selector {
  padding: 5px 0;
  overflow: hidden;
  border-top: 1px solid #1E9DD4;
  margin-bottom: 2px;
  text-align: right; }

/* line 1541, ../scss/all.scss */
.room-selector label {
  display: inline-block;
  margin-right: 10px;
  font-weight: bold;
  line-height: 28px; }

/* line 1548, ../scss/all.scss */
.room-selector select {
  padding: 4px;
  display: inline-block; }

/* line 1553, ../scss/all.scss */
.room-selector label:first-child,
.room-selector span.room-name {
  float: left; }

/* line 1558, ../scss/all.scss */
.room-selector span.room-name {
  padding: 6px;
  font-style: italic;
  display: block;
  max-width: 195px; }

/* line 1565, ../scss/all.scss */
.participant-list-container table tr:first-child td:first-child input {
  margin-left: 4px; }

/* line 1569, ../scss/all.scss */
.participant-list-container table tr td:first-child input {
  margin-left: 6px; }

/* line 1573, ../scss/all.scss */
.participant-list-container table td .not-registered {
  color: red; }

/* line 1578, ../scss/all.scss */
.participant-list-actions {
  margin-bottom: 10px; }

/* line 1582, ../scss/all.scss */
.participant-list-actions:after {
  content: "";
  clear: both;
  display: block; }

/* line 1588, ../scss/all.scss */
.grouptravel .participant-list-actions a {
  background-color: #1E9DD4;
  color: #FFFFFF;
  display: inline-block;
  padding: 0 10px;
  text-decoration: none;
  margin-right: 10px;
  text-transform: uppercase;
  font-size: 11px;
  line-height: 26px; }

/* line 1600, ../scss/all.scss */
.grouptravel .participant-list-actions .pull-right {
  background-image: none;
  float: right;
  margin-right: 0;
  padding: 0 10px; }

/* line 1607, ../scss/all.scss */
.grouptravel .participant-list-actions a .icon {
  position: relative;
  top: 4px;
  left: auto;
  margin-right: 7px; }

/* line 1614, ../scss/all.scss */
.participant-status {
  display: block;
  padding: 5px;
  width: 0;
  height: 0;
  margin: 0 auto; }

/* line 1622, ../scss/all.scss */
.participant-status.state-ok {
  background-color: #093; }

/* line 1626, ../scss/all.scss */
.participant-status.state-not-ok {
  background-color: #C00; }

/* simulate dropdown combo buttons */
/* line 1630, ../scss/all.scss */
.multiple-actions-container {
  min-height: 34px;
  position: relative; }

/* line 1635, ../scss/all.scss */
.multiple-actions-container .multiple-actions .ui-accordion-header span + span {
  display: block; }

/* line 1639, ../scss/all.scss */
.multiple-actions-container .ui-accordion.multiple-actions {
  background-color: transparent;
  margin-bottom: 10px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2; }

/* line 1648, ../scss/all.scss */
.multiple-actions-container .ui-accordion.multiple-actions.pull-right {
  left: 524px; }

/* line 1652, ../scss/all.scss */
.multiple-actions-container .ui-accordion.multiple-actions:after {
  content: "";
  display: block;
  clear: both; }

/* line 1658, ../scss/all.scss */
.multiple-actions-container .ui-accordion.multiple-actions .ui-accordion-header {
  border: none;
  color: #FFF;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 11px;
  padding: 5px;
  padding-right: 30px;
  background-color: #333; }

/* line 1668, ../scss/all.scss */
.multiple-actions-container .ui-accordion.multiple-actions .ui-accordion-header span.label {
  text-transform: uppercase;
  display: block; }

/* line 1673, ../scss/all.scss */
.multiple-actions-container .ui-accordion.multiple-actions .ui-accordion-header .ui-icon {
  border-width: 5px;
  top: 10px;
  right: 10px; }

/* line 1679, ../scss/all.scss */
.multiple-actions-container .ui-accordion.multiple-actions .ui-accordion-header .ui-icon-triangle-1-s {
  border-bottom-color: #FFF;
  border-bottom-width: 5px; }

/* line 1684, ../scss/all.scss */
.multiple-actions-container .ui-accordion.multiple-actions .ui-accordion-header .ui-icon-triangle-1-e {
  border-top-color: #FFF;
  border-top-width: 5px; }

/* line 1689, ../scss/all.scss */
.multiple-actions-container .ui-accordion.multiple-actions .ui-accordion-content:after {
  content: "";
  display: block;
  clear: both; }

/* line 1695, ../scss/all.scss */
.multiple-actions-container .ui-accordion.multiple-actions .ui-accordion-content ul {
  list-style-type: none;
  margin: 0;
  padding: 0; }

/* line 1701, ../scss/all.scss */
.multiple-actions-container .ui-accordion.multiple-actions .ui-accordion-content ul:after {
  content: "";
  display: block;
  clear: both; }

/* line 1707, ../scss/all.scss */
.multiple-actions-container .ui-accordion.multiple-actions .ui-accordion-content li {
  margin: 0;
  margin-top: 2px;
  background-color: #333; }

/* line 1713, ../scss/all.scss */
.multiple-actions-container .ui-accordion.multiple-actions .ui-accordion-content li a {
  text-transform: uppercase;
  color: #FFF;
  font-size: 11px;
  text-decoration: none;
  display: block;
  padding: 5px; }

/* line 1722, ../scss/all.scss */
.multiple-actions-container .ui-accordion.multiple-actions .ui-accordion-content li a:hover {
  background-color: #1E9DD4; }

/* line 1726, ../scss/all.scss */
.multiple-actions-container .ui-accordion.multiple-actions.colored .ui-accordion-header,
.multiple-actions-container .ui-accordion.multiple-actions.colored .ui-accordion-content li {
  background-color: #1E9DD4; }

/* line 1731, ../scss/all.scss */
.principal-booker-messages table tr.unread td {
  font-weight: bold; }

/* line 1735, ../scss/all.scss */
.offer-listing-container .multi-registration + table.total-row td {
  border-top: solid 2px #1e9dd4;
  padding-top: 10px;
  background-color: #FFF !important; }

/* line 1741, ../scss/all.scss */
.offer-listing-container .multi-registration + table.total-row tr + tr td {
  border-top: none;
  padding-top: 0px; }

/* line 1746, ../scss/all.scss */
.offer-listing-container .multi-registration + table.total-row td .price-label {
  display: inline-block;
  padding-right: 10px;
  text-align: right;
  width: 539px; }

/* line 1753, ../scss/all.scss */
.offer-listing-container .multi-registration + table.total-row td .price-value {
  display: inline-block; }

/* line 1758, ../scss/all.scss */
.offer-listing-container .principal-registerer + .total-row {
  border-top: solid 2px #1e9dd4; }

/* line 1762, ../scss/all.scss */
.offer-listing-container .principal-registerer + .total-row td {
  padding: 3px;
  padding-top: 10px;
  background-color: #FFF !important; }

/* line 1768, ../scss/all.scss */
.offer-listing-container .principal-registerer + .total-row td .price-label {
  display: inline-block;
  /* text-align:right;  */
  padding-right: 10px;
  width: 331px; }

/* line 1774, ../scss/all.scss */
.offer-listing-container .principal-registerer + .total-row td .price-value {
  display: inline-block;
  width: 88px; }

/* download list overlay */
/* line 1779, ../scss/all.scss */
.list-download .download-fields-list {
  list-style-type: none;
  width: 400px;
  margin: 0;
  padding: 0;
  margin-bottom: 20px; }

/* line 1787, ../scss/all.scss */
.list-download .download-fields-list:after {
  content: "";
  display: block;
  clear: both; }

/* line 1793, ../scss/all.scss */
.list-download .download-fields-list li {
  margin: 0;
  padding: 0;
  margin-bottom: 5px;
  width: 49.9%;
  float: left; }

/* line 1801, ../scss/all.scss */
.list-download .download-fields-list label {
  padding: 0 10px; }

/* Room / bus assignment and ocupancy */
/* line 1811, ../scss/all.scss */
.occupancy-config:after {
  content: "";
  clear: both;
  display: block; }

/* line 1817, ../scss/all.scss */
.name-list-container {
  float: left;
  max-height: 400px;
  overflow: auto;
  width: 49%; }

/* line 1824, ../scss/all.scss */
.occupancy-container {
  float: right;
  max-height: 400px;
  overflow: auto;
  width: 50%; }

/* line 1831, ../scss/all.scss */
.occupancy-config ul {
  list-style-type: none;
  margin: 0;
  padding: 0; }

/* line 1837, ../scss/all.scss */
.occupancy-config li {
  margin: 0;
  padding: 0; }

/* line 1842, ../scss/all.scss */
.occupancy-config .name-list {
  width: 99.9%;
  margin: 0; }

/* line 1847, ../scss/all.scss */
.occupancy-config .name-list dt {
  background-color: #DDD;
  font-weight: bold;
  margin: 0;
  margin-bottom: 2px;
  padding: 5px;
  cursor: default; }

/* line 1856, ../scss/all.scss */
.occupancy-config .name-list dd {
  background-color: #F5F5F5;
  margin: 0;
  padding: 5px;
  margin-bottom: 2px;
  font-size: 1em;
  border: none;
  height: 15px;
  cursor: pointer; }

/* line 1867, ../scss/all.scss */
.occupancy-config .occupancy-config-action {
  text-align: right;
  margin-bottom: 10px; }

/* line 1872, ../scss/all.scss */
.occupancy-config .occupancy-config-action > li {
  display: inline-block; }

/* line 1876, ../scss/all.scss */
.occupancy-config .occupancy-config-action > li a {
  padding: 0 5px;
  border-right: solid 1px #666;
  display: inline-block; }

/* line 1882, ../scss/all.scss */
.occupancy-config .occupancy-config-action > li + li + li a {
  border: none; }

/* line 1886, ../scss/all.scss */
.occupancy-config .occupancy-config-action > li input[type="checkbox"] {
  margin-left: 3px; }

/* line 1890, ../scss/all.scss */
.occupancy-config .occupancy-config-action > li label {
  color: #1E9DD4;
  text-decoration: underline;
  margin-left: 5px; }

/* line 1896, ../scss/all.scss */
.occupancy-config .occupancy-config-action > li label:hover {
  color: #333; }

/* line 1900, ../scss/all.scss */
.occupancy-config .occupancy {
  margin-bottom: 5px;
  width: 99.9%; }

/* line 1905, ../scss/all.scss */
.occupancy-config .occupancy > div:first-child {
  background-color: #DDD;
  font-weight: bold;
  padding: 5px;
  padding-right: 30px;
  margin-bottom: 0px;
  cursor: pointer;
  position: relative; }

/* line 1915, ../scss/all.scss */
.occupancy-config .occupancy > div:first-child span {
  border-left-width: 6px;
  border-right-width: 6px;
  right: 8px;
  top: 43%; }

/* line 1922, ../scss/all.scss */
.occupancy-config .occupancy > div:first-child .ui-icon-triangle-1-e {
  border-top-width: 6px; }

/* line 1926, ../scss/all.scss */
.occupancy-config .occupancy > div:first-child .ui-icon-triangle-1-s {
  border-bottom-width: 6px; }

/* line 1930, ../scss/all.scss */
.occupancy-config .occupancy > div:first-child + div {
  border: solid 1px #DDD;
  cursor: pointer; }

/* line 1935, ../scss/all.scss */
.occupancy-config .occupancy > div ul {
  padding: 5px; }

/* line 1939, ../scss/all.scss */
.occupancy-config .occupancy > div ul li {
  background-color: #F5F5F5;
  border: solid 1px #F5F5F5;
  padding: 5px;
  margin-bottom: 2px;
  width: 100%;
  font-size: 1em;
  border: none;
  width: auto;
  height: 13px; }

/* line 1954, ../scss/all.scss */
.occupancy-config .occupancy .ui-accordion-header .more-info a {
  font-size: 11px;
  font-weight: normal; }

/* line 1959, ../scss/all.scss */
.occupancy-config .occupancy .ui-accordion-header .available-places {
  display: inline-block;
  background-color: #333;
  color: #FFF;
  font-weight: normal;
  font-size: 11px;
  padding: 0 1px 1px 3px;
  margin-left: 5px;
  letter-spacing: 0.1em; }

/* line 1970, ../scss/all.scss */
.room.occupancy-config .name-list-container,
.room.occupancy-config .occupancy-container {
  height: auto;
  max-height: 400px; }

/* line 1976, ../scss/all.scss */
.occupancy-container .accommodation-accordion > .ui-accordion-header {
  padding: 5px;
  background-color: #bbb;
  margin-bottom: 10px;
  border-bottom: solid 1px #999; }

/* line 1983, ../scss/all.scss */
.occupancy-container .accommodation-accordion > .ui-accordion-header span {
  font-weight: bold;
  line-height: 4em; }

/* line 1988, ../scss/all.scss */
.occupancy-container .accommodation-accordion > .ui-accordion-header .ui-icon-triangle-1-e,
.occupancy-container .accommodation-accordion > .ui-accordion-header .ui-icon-triangle-1-s {
  border-left-width: 6px;
  border-right-width: 6px;
  right: 10px;
  top: 43%; }

/* line 1996, ../scss/all.scss */
.occupancy-container .accommodation-accordion > .ui-accordion-header .ui-icon-triangle-1-e {
  border-top-width: 6px; }

/* line 2000, ../scss/all.scss */
.occupancy-container .accommodation-accordion > .ui-accordion-header .ui-icon-triangle-1-s {
  border-bottom-width: 6px; }

/* line 2004, ../scss/all.scss */
.occupancy-config .pickup-point-title {
  font-size: 13px;
  margin-bottom: 1.5em; }

/* Room name & number overlay; bus number overlay */
/* line 2009, ../scss/all.scss */
.edit-room-details ul,
.edit-bus-details ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-bottom: 30px; }

/* line 2017, ../scss/all.scss */
.edit-room-details li,
.edit-bus-details li {
  padding: 0;
  margin: 0;
  margin-bottom: 5px; }

/* line 2024, ../scss/all.scss */
.edit-room-details li:after,
.edit-bus-details li:after {
  content: "";
  clear: both;
  display: block; }

/* line 2031, ../scss/all.scss */
.edit-room-details label,
.edit-bus-details label {
  float: left; }

/* line 2036, ../scss/all.scss */
.edit-room-details input[type="text"],
.edit-bus-details input[type="text"] {
  float: right;
  min-width: 150px; }

/* Bus assignment */
/* line 2042, ../scss/all.scss */
.bus-assignment .ui-accordion-header h3 {
  border-top: solid 1px #CCC;
  padding-top: 0.5em; }

/* line 2047, ../scss/all.scss */
.bus-assignment .ui-accordion-content {
  margin-bottom: 40px; }

/* line 2052, ../scss/all.scss */
.table-icons {
  list-style-type: none;
  overflow: hidden;
  margin: 0;
  padding: 0;
  padding-left: 2px; }

/* line 2060, ../scss/all.scss */
.table-icons li {
  display: inline-block;
  overflow: hidden;
  width: auto;
  height: 15px;
  margin: 0;
  padding: 0;
  margin-left: 5px;
  float: left; }

/* line 2071, ../scss/all.scss */
.table-icons li:first-child {
  margin-left: 0; }

/*# sourceMappingURL=all.css.map */
